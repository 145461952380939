<template>
  <div
    id="contact"
    class="container flex flex-col justify-center min-h-screen mx-auto my-14"
  >
    <h1
      class="w-full my-10 text-4xl font-bold tracking-widest text-center text-white xl:text-6xl"
    >
      talk with us!
    </h1>
    <div id="form" class="flex flex-col md:flex-row justify-evenly">
      <form @submit="checkForm" class="w-11/12 mx-auto xl:w-5/12">
        <div id="subjects" class="flex flex-wrap justify-between">
          <div
            v-for="sub in subjects"
            :key="sub"
            class="flex items-center justify-center my-5 rounded-md h-14 w-36 xl:w-44 bg-blue-dark shadow-accent"
            :class="{
              border: errors.subject,
              'border-red-500': errors.subject
            }"
          >
            <label class="flex items-center w-full h-full px-2 cursor-pointer">
              <input
                type="radio"
                class="form-radio"
                name="subject"
                v-model="subject"
                :value="sub"
                @change="resetErrors"
              />
              <span class="w-full ml-2 text-sm">{{ sub }}</span>
            </label>
          </div>
        </div>
        <input
          type="text"
          name="name"
          id="name"
          class="w-full h-12 p-2 my-4 rounded-md bg-blue-dark shadow-accent"
          placeholder="Your name"
          :class="{ border: errors.name, 'border-red-500': errors.name }"
          v-model="name"
          @input="resetErrors"
        />
        <input
          type="email"
          name="email"
          id="email"
          class="w-full h-12 p-2 my-4 rounded-md bg-blue-dark shadow-accent"
          placeholder="Your email"
          :class="{ border: errors.email, 'border-red-500': errors.email }"
          v-model="email"
          @input="resetErrors"
        />
        <textarea
          name="message"
          id="message"
          cols="60"
          rows="10"
          class="w-full p-2 mt-4 rounded-md resize-none bg-blue-dark shadow-accent"
          placeholder="Your message"
          :class="{ border: errors.message, 'border-red-500': errors.message }"
          v-model="message"
          @input="resetErrors"
        ></textarea>
        <button
          type="submit"
          class="block p-4 mx-auto mt-5 rounded-full font-gimlet text-blue-dark md:mx-0 xl:mx-0 bg-blue-light xl:w-48 xl:h-16 hover:text-white hover:bg-blue-normal"
        >
          Send message
        </button>
      </form>
      <div
        id="info"
        class="flex flex-col justify-between w-11/12 py-6 mx-auto mt-5 text-center rounded-md shadow-accent md:w-7/12 md:ml-5 lg:w-6/12 xl:w-auto xl:ml-10 px-9 bg-blue-dark"
      >
        <h2 class="text-2xl font-bold">Contact us via email</h2>
        <h3 class="mt-4 text-xl font-bold">General enquiries</h3>
        <p class="mt-2 font-bold text-blue-light">enquiries@prizma.gg</p>
        <h3 class="mt-10 text-xl font-bold">Support</h3>
        <p class="mt-2 font-bold text-blue-light">support@prizma.gg</p>
        <div id="socials">
          <h2 class="text-2xl font-bold mt-14">Contact us on social media</h2>
          <ul class="flex justify-center mt-6">
            <li class="mx-2">
              <a
                href="https://www.facebook.com/prizmagamesofficial"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  width="32"
                  height="33"
                  class="fill-current hover:text-socials-facebook"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M28.125.75H3.375A3.376 3.376 0 000 4.125v24.75c0 1.898 1.477 3.375 3.375 3.375h9.633V21.562h-4.43V16.5h4.43v-3.797c0-4.36 2.601-6.82 6.539-6.82 1.969 0 3.937.351 3.937.351v4.29h-2.18c-2.179 0-2.882 1.335-2.882 2.742V16.5h4.851l-.773 5.063h-4.078V32.25h9.703a3.376 3.376 0 003.375-3.375V4.125C31.5 2.297 29.953.75 28.125.75z"
                  />
                </svg>
              </a>
            </li>
            <li class="mx-2">
              <a
                href="https://www.instagram.com/prizmagames"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  width="32"
                  height="33"
                  class="fill-current hover:text-socials-instagram"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.75 12.773c-2.11 0-3.797 1.688-3.797 3.727a3.781 3.781 0 003.797 3.797c2.04 0 3.727-1.688 3.727-3.797 0-2.04-1.688-3.727-3.727-3.727zm8.719-2.882a3.482 3.482 0 00-2.11-2.11c-1.476-.633-4.992-.492-6.609-.492-1.688 0-5.203-.14-6.68.492a3.482 3.482 0 00-2.11 2.11c-.562 1.476-.491 4.992-.491 6.609 0 1.688-.07 5.203.492 6.68a3.482 3.482 0 002.11 2.11c1.476.632 4.992.491 6.679.491 1.617 0 5.133.14 6.61-.492a3.483 3.483 0 002.109-2.11c.633-1.476.492-4.991.492-6.679 0-1.617.14-5.133-.492-6.61zM15.75 22.266c-3.234 0-5.766-2.532-5.766-5.766a5.746 5.746 0 015.766-5.766c3.164 0 5.766 2.602 5.766 5.766v.07c0 3.164-2.602 5.766-5.766 5.766v-.07zm5.977-10.407c-.774 0-1.336-.562-1.336-1.336 0-.773.562-1.335 1.336-1.335a1.335 1.335 0 110 2.671zM28.125.75H3.375A3.376 3.376 0 000 4.125v24.75c0 1.898 1.477 3.375 3.375 3.375h24.75a3.376 3.376 0 003.375-3.375V4.125C31.5 2.297 29.953.75 28.125.75zm-1.266 20.39c-.07 1.829-.492 3.446-1.757 4.712-1.336 1.335-2.954 1.757-4.711 1.828-1.899.14-7.453.14-9.282 0-1.828-.07-3.445-.492-4.71-1.828-1.337-1.266-1.758-2.883-1.829-4.711-.14-1.828-.14-7.383 0-9.282.07-1.757.492-3.375 1.828-4.71C7.664 5.882 9.281 5.46 11.11 5.39c1.829-.141 7.383-.141 9.282 0 1.757.07 3.375.492 4.71 1.828 1.266 1.265 1.688 2.883 1.758 4.71.141 1.829.141 7.383 0 9.212z"
                  />
                </svg>
              </a>
            </li>
            <li class="mx-2">
              <a
                href="https://www.twitter.com/prizmagames"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  width="32"
                  height="33"
                  class="fill-current hover:text-socials-twitter"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M28.125.75H3.375A3.376 3.376 0 000 4.125v24.75c0 1.898 1.477 3.375 3.375 3.375h24.75a3.376 3.376 0 003.375-3.375V4.125C31.5 2.297 29.953.75 28.125.75zM24.68 11.93v.633c0 6.046-4.64 13.078-13.149 13.078-2.601 0-4.992-.703-7.031-2.04.352.07.703.07 1.055.07 2.18 0 4.148-.773 5.765-1.968a4.787 4.787 0 01-4.36-3.234c.774.14 1.407.14 2.11-.07-2.11-.422-3.656-2.25-3.656-4.5v-.07a3.844 3.844 0 002.04.562 4.548 4.548 0 01-2.04-3.797c0-.914.211-1.688.633-2.39 2.25 2.812 5.695 4.64 9.492 4.85-.633-3.093 1.688-5.695 4.5-5.695 1.336 0 2.531.563 3.375 1.477 1.055-.211 2.04-.563 2.953-1.125-.351 1.125-1.125 1.969-2.039 2.531.914-.07 1.828-.351 2.672-.703a10.719 10.719 0 01-2.32 2.39z"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="errors.length" class="my-4 ml-40 text-red-500">
      <p>Please correct the following error(s):</p>
      <ul>
        <li v-for="error in errors" :key="error">{{ error }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      subject: null,
      name: null,
      email: null,
      message: null,
      errors: {
        subject: false,
        name: false,
        email: false,
        message: false
      },
      subjects: [
        "Job Opportunities",
        "Partnerships",
        "Press Relations",
        "General Inquiries",
        "Support",
        "Content Creator"
      ]
    };
  },
  methods: {
    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    resetErrors() {
      for (const key in this.errors) {
        if (this[key] && key !== "email") {
          this.errors[key] = false;
        } else if (key === "email") {
          if (this.validEmail(this.email)) {
            this.errors.email = false;
          }
        }
      }
    },
    checkForm(e) {
      e.preventDefault();

      if (!this.subject) {
        this.errors.subject = true;
      }

      if (!this.name) {
        this.errors.name = true;
      }

      if (!this.email) {
        this.errors.email = true;
      } else if (!this.validEmail(this.email)) {
        this.errors.email = true;
      }

      if (!this.message) {
        this.errors.message = true;
      }

      if (
        this.validEmail(this.email) &&
        this.subject &&
        this.name &&
        this.message
      ) {
        fetch("http://localhost:5000/api/v1/form", {
          method: "POST",
          body: JSON.stringify({
            subject: this.subject,
            name: this.name,
            email: this.email,
            message: this.message
          }),
          headers: { "Content-Type": "application/json" }
        })
          .then((res) => res.json()) // expecting a json response
          .then((json) => console.log(json));
      }
    }
  }
};
</script>

<style scoped></style>
